<template>
  <v-main>
    <v-container>
      <h1 class="display-1">Solar</h1>
      <div>
        <div>
          <p>
            Hundreds of residents have installed solar photovoltaic (“PV”)
            systems on their roofs and in their yards. These systems are
            producing reliable and cost-effective renewable energy to power the
            home or business located there. Many other residents are considering
            adding solar power, especially as costs have come down considerably
            in the past 10 years.
          </p>
        </div>
        <div>
          <h2 class="title">Existing Solar Systems</h2>
          <p>
            The Solar feature of Peeps allows you to record key information
            about your solar installation. Some people have even added a second
            solar system, which Peeps can also keep track of for you.
          </p>
          <p>
            Once you tell Peeps about your solar, the app can do some basic
            calculations to estimate how much electricity you will generate from
            your solar system. This will feed into your Greenhouse Gas Profile,
            and help to complete your entire inventory of your contribution to
            mitigating climate change. And of course it will also estimate your
            annual cost savings on your electric bill. For a few minutes of
            entering your basic solar information, you’ll be rewarded with some
            valuable insights.
          </p>
          <!-- <p>
            As a Truro resident, your solar information was already provided on
            your building permit, and is public information. So to make it even
            easier for you to update your solar system information, Peeps (who
            loves to conserve energy, especially yours) will look for and
            automatically provide that information to you so you can simply
            review it for accuracy and update it as necessary.
          </p> -->
        </div>
        <div>
          <h2 class="title">Planned Solar Systems</h2>
          <p>
            If you’re considering installing a solar system, Peeps can inform
            you of special programs that are run from time to time. And provide
            you with the contact information of solar companies that service the
            town of Truro.
          </p>
          <p>
            The Truro Energy Committee has been active for over a decade in
            coaching and helping Truro residents learn about and install solar
            systems. If you like, Peeps can put you in touch with a volunteer
            “solar coach” who can help guide you through the process of learning
            and eventually contracting for your own solar system.
          </p>
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'About Solar'
    }
  }
}
</script>
